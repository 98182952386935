<template>
  <div class="bg-white">
    <TsRow append-class="m-0 p-4">
      <TsColumn append-class="p-0 is-0 ms-1">
        <TsIcon name="humbleicons:cart" size="27" />
      </TsColumn>
      <TsColumn append-class="p-0 is-11 lg:flex lg:gap-2 lg:items-center">
        <TsTypography append-class="mb-0" as="h4">
          {{
            useTranslation(
              "itemsAddedToTrolley",
              "Artikelen toegevoegd aan winkelwagen"
            )
          }}
        </TsTypography>
        <TsTag
          variant="info"
          append-class="outline-0 rounded-lg py-1"
          size="sm"
          :data-testid="`collection-add-to-trolley-modal-channel-tag`"
        >
          {{
            props.loading
              ? trolleyStore.currentTrolleyAction === "NextDayCollection"
                ? useTranslation("nextDayCollection", "Ophalen de volgende dag")
                : useTranslation("collection", "Verzameling")
              : enableNextDayCollection
              ? useTranslation("nextDayCollection", "Ophalen de volgende dag")
              : useTranslation("collection", "Verzameling")
          }}
        </TsTag>
      </TsColumn>
      <TsColumn append-class="p-0 is-1">
        <TsIcon
          name="bitcoin-icons:cross-filled"
          @click="handleModalClose"
          :data-testid="`collection-add-to-trolley-modal-close-icon`"
          class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />
      </TsColumn>
    </TsRow>

    <TsDivider append-class="w-full p-0 m-0" />

    <div
      class="p-5"
      v-if="props.loading"
      :data-testid="`collection-add-to-trolley-modal-initial-loader`"
    >
      <TsBranchTransition v-for="n in 3" :key="n" />
    </div>

    <div v-else class="max-h-[70vh] overflow-y-auto">
      <div class="px-4 mt-3">
        <template v-if="trolleyStore.previewed_product_v2?.variations?.length">
          <TsTypography
            append-class="mb-3"
            as="h4"
            :data-testid="`collection-add-to-trolley-modal-number-of-variants`"
          >
            {{
              useTranslation("selectProductTypes", "Select Product Variants")
            }}
            ({{ trolleyStore.previewed_product_v2.variations.length }})
          </TsTypography>
          <TsButton
            :label="trolleyStore?.previewed_product_v2?.pack_size"
            @click="handleCollectionVariantsClick"
            variant="secondary"
            icon="pepicons-pop:angle-down"
            :data-testid="`collection-add-to-trolley-modal-select-variant-button`"
            iconPosition="right"
            append-class="mb-4 mt-0 bg-white justify-between w-full"
            iconClass="text-natural-silver-grey"
            outlined
          />
        </template>

        <TsMedia>
          <TsMediaStart>
            <TsQuantityCounter
              @increment="updateTrolleyItemQuantity"
              @decrement="updateTrolleyItemQuantity"
              @change="updateTrolleyItemQuantity"
              v-model="lineItem.quantity"
              :data-testid="`collection-add-to-trolley-modal-quantity-control`"
            />
            <TsTag
              v-if="enableOutOfStock"
              :label="outOfStock"
              :data-testid="`collection-add-to-trolley-modal-out-of-stock-tag`"
              variant="danger"
              rounded
              size="sm"
              append-class="m-0 mt-2 py-0.5 px-5"
            />
            <TsTag
              v-else
              variant="info"
              :data-testid="`collection-add-to-trolley-modal-available-stock-tag`"
              rounded
              size="sm"
              append-class="m-0 mt-2 py-0.5 px-5"
              :label="
                availableStockForCollection + ' ' +
                useTranslation('available', ' beschikbaar')
              "
            />
          </TsMediaStart>
          <TsMediaContent append-class="ms-4">
            <TsRow
              v-if="
                trolleyStore.previewed_product_v2?.prices?.was_price_raw ??
                0 > 0
              "
              append-class="m-0"
              :data-testid="`collection-add-to-trolley-modal-recommended-retail-price-old`"
            >
              <div
                :class="`trolleyStore.previewed_product_v2.prices?.raw.gross < trolleyStore.previewed_product_v2.prices.was_price_raw && h-4`"
              >
                <TsTypography
                  size="sm"
                  append-class="line-through leading-4 m-0 text-natural-grey"
                  v-if="
                    trolleyStore.previewed_product_v2?.prices &&
                    trolleyStore.previewed_product_v2?.prices?.raw &&
                    trolleyStore.previewed_product_v2?.prices
                      ?.was_price_formatted &&
                    trolleyStore.previewed_product_v2?.prices?.raw.gross <
                      trolleyStore.previewed_product_v2?.prices.was_price_raw
                  "
                >
                  {{
                    trolleyStore.previewed_product_v2?.prices
                      ?.was_price_formatted
                  }}
                </TsTypography>
              </div>
              <TsTypography
                v-if="
                  trolleyStore.previewed_product_v2?.prices?.raw
                    .discount_percentage
                "
                size="sm"
                weight="bold"
                append-class="text-success leading-tight"
                :data-testid="`collection-add-to-trolley-modal-discount-percentage`"
              >
                &nbsp; ({{
                  trolleyStore.previewed_product_v2?.prices?.formatted
                    .discount_percentage
                }}
                OFF)
              </TsTypography>
            </TsRow>
            <TsRow append-class="m-0 items-center gap-1">
              <TsTypography
                variant="h2"
                weight="bold"
                append-class="text-idle-black mb-0 leading-6"
                :data-testid="
                  rootStore.isIncVat
                    ? `collection-add-to-trolley-modal-gross-price`
                    : `collection-add-to-trolley-modal-net-price`
                "
              >
                {{
                  rootStore.isIncVat
                    ? trolleyStore.previewed_product_v2?.prices?.formatted.gross
                    : trolleyStore.previewed_product_v2?.prices?.formatted.net
                }}
              </TsTypography>
              <TsTypography
                append-class="m-0 leading-4 text-natural-silver-grey"
                size="xs"
                :data-testid="`collection-add-to-trolley-modal-formatted-per-unit-price`"
              >
                {{
                  trolleyStore.previewed_product_v2?.prices?.formatted.per_unit
                }}
              </TsTypography>
            </TsRow>
            <TsTypography
              variant="xs"
              append-class="leading-4 text-natural-grey"
            >
              <span
                :data-testid="`collection-add-to-trolley-modal-vat-indicator-text`"
              >
                {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
              </span>
              <span
                :data-testid="
                  rootStore.isIncVat
                    ? `collection-add-to-trolley-modal-net-price`
                    : `collection-add-to-trolley-modal-gross-price`
                "
              >
                {{
                  rootStore.isIncVat
                    ? trolleyStore.previewed_product_v2?.prices?.formatted.net
                    : trolleyStore.previewed_product_v2?.prices?.formatted.gross
                }}
              </span>
            </TsTypography>
          </TsMediaContent>
        </TsMedia>

        <TsRow append-class="m-0">
          <TsDivider append-class="w-full mb-2" />
        </TsRow>

        <TsRow
          v-if="enableOutOfStock || enableNextDayCollection"
          is-multiline
          append-class="m-0 mb-3"
        >
          <TsColumn append-class="is-1 p-0 mt-1">
            <NuxtImg
              src="./images/store-location-icon.png"
              width="30"
              class="shrink-0"
            />
          </TsColumn>
          <TsColumn append-class="is-11 p-0">
            <ClientOnly>
              <TsTypography
                v-if="branchStore.lastSavedBranch"
                size="base"
                weight="semibold"
                data-testid="collection-add-to-trolley-modal-click-and-collect-branch"
                append-class="text-secondary ms-4 md:ms-0"
              >
                {{
                  useTranslation(
                    "insufficientStockAt",
                    "Onvoldoende voorraad bij"
                  )
                }}
                {{ branchStore.lastSavedBranch.name }}
              </TsTypography>
            </ClientOnly>
            <TsTypography size="sm" append-class="text-idle-black ms-4 md:ms-0">
              {{
                useTranslation(
                  "needTheProductNow",
                  "Heb je het product nu nodig?"
                )
              }}
            </TsTypography>
            <TsRow append-class="m-0 justify-between ms-4 md:ms-0">
              <TsButton
                data-testid="collection-add-to-trolley-modal-find-stock-nearby-cta"
                size="sm"
                append-class="text-primary p-0 underline border-none hover:bg-white bg-white hover:text-primary"
                @click="emit('findStock', lineItem.quantity)"
                >{{
                  useTranslation("findStockNearby", "Vind voorraad in de buurt")
                }}
              </TsButton>
              <TsButton
                size="sm"
                @click="emit('setBranch')"
                data-testid="collection-add-to-trolley-modal-change-branch-cta"
                append-class="text-primary p-0 underline border-none hover:bg-white bg-white hover:text-primary"
              >
                {{ useTranslation("changeBranch", "Vestiging wijzigen") }}
              </TsButton>
            </TsRow>
          </TsColumn>
        </TsRow>

        <TsRow v-else is-multiline append-class="m-0 mb-3">
          <ClientOnly>
            <TsColumn append-class="p-0 is-1 mt-1.5">
              <NuxtImg
                src="./images/store-location-icon.png"
                width="30"
                class="shrink-0"
              />
            </TsColumn>
            <TsColumn
              append-class="p-0 is-11 flex justify-between items-center mt-2"
            >
              <TsTypography
                v-if="branchStore.lastSavedBranch"
                size="base"
                weight="semibold"
                data-testid="collection-add-to-trolley-modal-click-and-collect-branch"
                append-class="text-idle-black mb-1 ms-2 md:ms-0"
              >
                {{ useTranslation("pickUpAt", "Ophalen bij") }}
                {{ branchStore.lastSavedBranch.name }}
                {{ useTranslation("in15Mins", "in 15 minuten") }}
              </TsTypography>
              <TsButton
                size="sm"
                @click="emit('setBranch')"
                data-testid="collection-add-to-trolley-modal-change-branch-cta"
                append-class="text-primary p-0 underline border-none hover:bg-white bg-white hover:text-primary mb-1"
              >
                {{ useTranslation("changeBranch", "Vestiging wijzigen") }}
              </TsButton>
            </TsColumn>
          </ClientOnly>
        </TsRow>
      </div>

      <div class="bg-natural-soft-white">
        <TsColumn append-class="pb-1">
          <TsTypography
            weight="semibold"
            append-class="text-idle-black pl-3 leading-5"
          >
            {{ useTranslation("suggestion", "Misschien vind je het ook leuk") }}
          </TsTypography>
        </TsColumn>
        <TsRow
          append-class="m-0 px-3 pb-1 flex overflow-x-auto whitespace-nowrap no-scrollbar"
          monetate-id="collection-delivery-you-may-also-like"
        >
          <TsModalProductCard
      v-for="product in products"
      :key="product.code"
      :product="product"
      :addToTrolleyLabel="useTranslation('addToTrolley', 'Add to Trolley')"
    />
        </TsRow>
      </div>
    </div>

    <template v-if="!props.loading">
      <TsRow append-class="px-0 lg:px-36 p-2" v-if="enableOutOfStock">
        <TsColumn>
          <TsButton
            v-if="isSubscribedToStock(trolleyStore.previewed_product_v2!.code)"
            icon="material-symbols:unsubscribe-outline"
            :label="unsubscribeLabel"
            data-testid="collection-add-to-trolley-stock-notification-unsubscribe-cta"
            :is-loading="isStockUnsubscribeCTAloading"
            @click="handleStockUnsubscribe(trolleyStore.previewed_product_v2!.code)"
            outlined
            block
          />
          <TsButton
            v-else
            icon="mdi:bell-outline"
            :label="notifyMeLabel"
            data-testid="collection-add-to-trolley-out-of-stock-notification-cta"
            @click="emit('notify', trolleyStore.previewed_product_v2!.code, trolleyStore.previewed_product_v2!.full_name || trolleyStore.previewed_product_v2!.name)"
            block
          />
        </TsColumn>
      </TsRow>

      <TsRow
        append-class="px-0 lg:px-36 p-2"
        v-else-if="enableNextDayCollection"
      >
        <TsColumn>
          <TsButton
            block
            icon="hugeicons:store-verified-01"
            :label="
              useTranslation(
                'nextDayCollectionAt',
                `Volgende dag ophalen om ${branchStore.lastSavedBranch?.name}`
              )
            "
            @click="emit('goToTrolley')"
            data-testid="collection-add-to-trolley-next-day-go-to-trolley-cta"
            size="sm"
          />
        </TsColumn>
      </TsRow>

      <TsRow append-class="m-0 justify-between p-2" v-else>
        <TsColumn>
          <TsButton
            block
            append-class="h-full"
            :label="continueShoppingCTALabel"
            data-testid="collection-add-to-trolley-continue-shopping-cta"
            size="sm"
            outlined
            @click="emit('close')"
          />
        </TsColumn>
        <TsColumn>
          <TsButton
            block
            append-class="h-full"
            icon="mdi:trolley-outline"
            :label="goToTrolleyCTALabel"
            data-testid="collection-add-to-trolley-go-to-trolley-cta"
            @click="emit('goToTrolley')"
            size="sm"
          />
        </TsColumn>
      </TsRow>
    </template>

    <!-- Overlay Loader -->
    <div
      v-if="trolleyItemProcessing"
      class="absolute inset-0 bg-idle-black/15 z-10 rounded-lg"
      data-testid="collection-add-to-trolley-processing-loader-overlay"
    >
      <!-- Progress Bar -->
      <div
        class="absolute top-0 left-0 z-50 h-2 transition-all bg-primary"
        data-testid="collection-add-to-trolley-progress-bar-loader"
        :style="{ width: progressBarWidth + '%' }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductService } from "~/services/product.service";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";
import { useTranslation } from "~/composables/useTranslation";

const branchStore = useBranchStore();
const rootStore = useRootStore();
const monetateStore = useMonetateStore();
const products = ref([])

onMounted(async()=>{
  if(!monetateStore.categories_you_may_also_like_ids) return; 
  const data_ids = monetateStore.categories_you_may_also_like_ids.map(item => +item)
  if(data_ids.length === 0){
    return false;
  }
  products.value = await new ProductService().getProducts(data_ids);
})

type Props = {
  loading?: boolean;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  setBranch: any;
  close: any;
  notify: [code: string, name: string];
  goToTrolley: any;
  clickVariants: any;
  findStock: [quantity: number];
}>();

const trolleyStore = useTrolleyStore();

function handleModalClose() {
  emit("close");
}

const continueShoppingCTALabel = useTranslation(
  "continueShopping",
  "Ga door met winkelen"
);
const goToTrolleyCTALabel = useTranslation("goToTrolley", "Ga naar karretje");
const addToTrolley = useTranslation("addToTrolley", "Toevoegen aan karretje");
const notifyMeLabel = useTranslation("notifyMe", "Breng mij op de hoogte");
const unsubscribeLabel = useTranslation("unsubscribe", "Uitschrijven");
const outOfStock = useTranslation("outOfStock", "Niet op voorraad");

// OOS notifications
const isStockUnsubscribeCTAloading = ref(false);

function isSubscribedToStock(code: string) {
  const user: any = useAuthStore().user;
  if (!user || !user.id) return false;

  return useAccountStore().stock_notifications.some(
    (notification: any) => notification.product_id === code
  );
}

async function handleStockUnsubscribe(productCode: string) {
  isStockUnsubscribeCTAloading.value = true;
  await useAuthStore().onStockUnsubscribe(productCode);
  isStockUnsubscribeCTAloading.value = false;
}

// main indicator
const enableNextDayCollection = computed<boolean>(() => {
  return (
    (trolleyStore.previewed_product_v2?.next_business_day_collection &&
      trolleyStore.previewed_product_v2?.outOfStockForCollection &&
      !trolleyStore.previewed_product_v2?.outOfStockForDelivery) ||
    // Previously, we were comparing `trolleyStore.collection_quantity_counter` instead of `lineItem.quantity`
    // synced with trolley now
    (lineItem.quantity >
      trolleyStore.previewed_product_v2?.stockDetails?.collection! &&
      lineItem.quantity <=
        trolleyStore.previewed_product_v2?.stockDetails?.delivery!)
  );
});

const enableOutOfStock = computed<boolean>(() => {
  return (
    (trolleyStore.previewed_product_v2?.outOfStockForCollection &&
      trolleyStore.previewed_product_v2?.outOfStockForDelivery) ||
    (lineItem.quantity >
      trolleyStore.previewed_product_v2?.stockDetails?.collection! &&
      lineItem.quantity >
        trolleyStore.previewed_product_v2?.stockDetails?.delivery!)
  );
});

const availableStockForCollection = computed(() =>
  enableNextDayCollection.value
    ? trolleyStore.previewed_product_v2?.stockDetails?.delivery
    : trolleyStore.previewed_product_v2?.stockDetails?.collection
);

const trolleyItemProcessing = ref(false);

const lineItem = reactiveComputed<any>(() => {
  if (!trolleyStore.trolley_line_items) return { quantity: 1 };
  return (
    Object.values(trolleyStore.trolley_line_items)
      .flatMap((item) => item)
      .find(
        (line) => line.product_code === trolleyStore.previewed_product_v2?.code
      ) ?? { quantity: 1 }
  );
});

// clicking on collection variants
function handleCollectionVariantsClick() {
  trolleyStore.collection_product_variants = [];
  if (!trolleyStore.previewed_product_v2?.variations.length) return;

  trolleyStore.preview_overlay_collection_visible = false;
  trolleyStore.collection_variants_modal_visible = true;
  const stockStore = useStockStore();

  stockStore
    .getStockForProducts(trolleyStore.previewed_product_v2?.variations)
    .then((response) => {
      trolleyStore.collection_product_variants = response.map((product) => ({
        ...product,
        quantity: 0,
        channel: stockStore.isEligibleForNextDayCollection(product)
          ? TrolleyChannel.NextDayCollection
          : TrolleyChannel.Collection,
      }));
    });
}

const updateTrolleyItemQuantity = async () => {
  // return if completely out of stock
  if (enableOutOfStock.value || !lineItem) return;

  trolleyItemProcessing.value = true;

  // update product quantity in trolley
  await trolleyStore.updateQuantity(lineItem);

  // dispatch for next day collection when quantity is increased and within bound
  if (enableNextDayCollection.value)
    await trolleyStore.updateChannel(
      lineItem.id,
      TrolleyChannel.NextDayCollection
    );
  // when quantity is reduced send to collection
  else await trolleyStore.updateChannel(lineItem.id, TrolleyChannel.Collection);
  trolleyItemProcessing.value = false;
};

// for loading overlay
watch(trolleyItemProcessing, (isProcessing) => {
  if (isProcessing) {
    startProgress();
  } else {
    resetProgress();
  }
});

const progressBarWidth = ref(0);
let intervalId: NodeJS.Timeout | null = null;

const startProgress = () => {
  progressBarWidth.value = 0;
  intervalId = setInterval(() => {
    if (progressBarWidth.value < 100) {
      progressBarWidth.value += 1;
    } else {
      clearInterval(intervalId!);
    }
  }, 5);
};

const resetProgress = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
  progressBarWidth.value = 0;
};
</script>
